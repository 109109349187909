import React, { useEffect, useState } from "react";
import { get } from '../../axios'
import styles from './index.module.css'

import FiveElementScore from "../FiveElementScore";
import PositiveDagger from "./PositiveDagger";
import Salary from "./Salary";
import Combination from "./Combination";

import Tabs from 'react-responsive-tabs'; //tabpage
import 'react-responsive-tabs/styles.css';//tabpage
import UseGod from "./UseGod";
import TaiSui from "./TaiSui";
import StartFortune from "./StartFortune";
import { compareBirthday } from "../../Helper/DateTimeHelper";
import { globalData } from "../../Helper/global";
import { parseHoroscope } from "../../Helper/AddressObj"
import RelationTable from "../Horosope/RelationTable"
import MsonSelectorUI from "./MsonSelectorUI";
import MsonSelector from "../MsonSelector";

export default function Horosope(props) {
    const [horosopeResult, setHorosopeResult] = useState()  //the reply of horosope api
    const [analysisResult, setAnalysisResult] = useState()  //the reply of analysis api

    const [inputDate, setInputDate] = useState();   //cache value for comparison
    const [isMale, setIsMale] = useState();     //cache value for comparison


    const [strongWeakText, setStrongWeakText] = useState("")  //旺弱

    const [useGod1, setUseGod1] = useState("");
    const [useGod2, setUseGod2] = useState("");
    const [taiSuiQueryTime, setTaiSuiQueryTime] = useState(0);

    const endOriginal8Letter = 19;




    /**
     * the timing to refresh data whenever inputDate or isMale change
     */
    useEffect(() => {
        //set cahce data for comparison
        setInputDate(props.inputDate)
        setIsMale(props.isMale)

        //download horosope data
        if (props && props.inputDate && (props.inputDate != inputDate || props.isMale != isMale) && compareBirthday(props.inputDate, globalData.inputDate)) {
            //clear result before making request
            console.log("horoscope:request " + props.inputDate + " " + globalData.inputDate + "-- run --")

            setHorosopeResult(null)
            setAnalysisResult(null)
            setStrongWeakText("")

            get(`api/birthday/horoscope/${props.inputDate}/ismale/${props.isMale}?t=${Date.now()}`).then(res => {
                if (res.data.success) {
                    if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {
                        setHorosopeResult(res.data.data)

                        //分析月令
                        let monthBranchAnalysis = parseMonthBranchAnalysis(res.data.data)
                        setStrongWeakText(monthBranchAnalysis)
                    } else {
                        console.log("Horosope birthday " + res.data.data.birthday + " is not up to date.")
                    }

                    //print debug message of whole letter address
                    // const address = res.data.letterAddress.string.trim()
                    // let result = ""
                    // for (let i = 0; i < address.length; i++) {
                    //     result += (i + ".\t" + address[i] + "\r\n")
                    // }

                    // console.log(result)
                }
            })
        } else {
            console.log("analysis: block horoscope request by compare birthday " + props.inputDate + " " + globalData.inputDate)
        }

    }, [props.inputDate, props.isMale])

    /**
     * both address and schema are also ready. Start elaborating
     */
    useEffect(() => {
        if (props.addressSchema && props.year10Ready && compareBirthday(props.inputDate, globalData.inputDate)) {
            console.log("analysis:request " + props.inputDate + " " + globalData.inputDate + "-- run --")
            //When year10 is ready, get analysisResult
            get(`api/birthday/analysis/${props.inputDate}/ismale/${props.isMale}?t=${Date.now()}`).then(res => {

                if (res.data.success) {
                    if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {

                        let objAnalysisResult = parseAnalysisResultToObj(res.data.data)
                        setAnalysisResult(objAnalysisResult)
                        props.setIsAnalysisReady(true)  //update ready status to EightLettersPage
                    } else {
                        console.log("Analysis birthday " + res.data.data.birthday + " is not up to date.")
                    }
                }
            })
            //分析月令


            //setCombinationMonthText(parseAddressAllDate(horosopeResult, startCombineSkytoEarth, endCombineSkytoEarthYearMonth, false, new Date("1995-02-01T00:00:00")))
        } else {
            console.log("analysis: block analysis request by compare birthday " + props.year10Ready + " " + props.inputDate + " " + globalData.inputDate)
        }
    }, [props.year10Ready, props.addressSchema])


    const parseAnalysisResultToObj = (analysisData) => {
        return parseHoroscope(analysisData)
    }


    const parseMonthBranchAnalysis = (horoscopeData) => {
        let result = "";

        if (horoscopeData && horoscopeData.monthBranchAnalysis) {
            const analysis = horoscopeData.monthBranchAnalysis

            result += "- "
            result += (horoscopeData.letterAddress.string.trim()[4] + "生於" + analysis.month + analysis.seasonType + ",")
            result += analysis.isMatched ? "得令," : "不得令,"
            result += `${horoscopeData.letterAddress.string.trim()[4]}${analysis.dayStemStatus}於${horoscopeData.letterAddress.string.trim()[3]},`

            result += "宜用" + analysis.suggestion
            result += "\r\n"
        }
        return result;
    }






    const getUseGodUI = () => {
        return (
            <UseGod useGod1={useGod1} setUseGod1={setUseGod1}
                useGod2={useGod2} setUseGod2={setUseGod2} />

        )
    }


    const getStrongWeakUI = () => {
        return (
            <>
                <FiveElementScore inputDate={inputDate} isMale={isMale} />
                <p></p>
                <textarea className={`${styles.horosopeTextArea}`} readOnly value={strongWeakText} />
            </>
        )
    }




    const getCombinationMonthUI = () => {
        return (
            analysisResult &&
            <Combination
                inputDate={inputDate} isMale={isMale}
                analysisResult={analysisResult} addressSchema={props.addressSchema} />
        )
    }


    const [debugAddress, setDebugAddress] = useState('');

    const handleDebugAddressChange = (event) => {
        setDebugAddress(event.target.value);
    };

    const getDebugUI = () => {


        return (<>

            <textarea className={`${styles.horosopeTextArea}`} onChange={handleDebugAddressChange} value={debugAddress} />

            <table style={{ 'fontSize': '70%' }}>
                <tbody>
                    <tr>
                        {debugAddress.split('').map((char, index) => (
                            <td key={index} style={{ margin: '0px', border: '1px solid grey' }}>
                                {index}
                            </td>
                        ))}
                    </tr>
                    <tr>
                        {debugAddress.split('').map((char, index) => (
                            <td key={index} style={{ margin: '0px', border: '1px solid grey' }}>
                                {char}
                            </td>
                        ))}
                    </tr>



                    {debugAddress && props.addressSchema &&
                        <tr>
                            {props.addressSchema.map((ac, index) => (
                                (index < debugAddress.length) &&
                                <td key={index} style={{ margin: '0px', border: '1px solid grey' }}>
                                    {ac.pillar ? ac.pillar + ac.skyearth + ac.Chi : (ac.position ? ac.position + ac.combination + ac.index1 + "," + ac.index2 + "," + ac.index3 : "")}
                                </td>

                            ))
                            }
                        </tr>
                    }
                </tbody>
            </table>
        </>)
    }


    const getPositiveDaggerUI = () => {
        return (
            //分析陽刃
            analysisResult &&
            <PositiveDagger className={`${styles.horosopeTextArea}`}
                analysisResult={analysisResult} endOriginal8Letter={endOriginal8Letter}
                addressSchema={props.addressSchema} />
        )
    }

    const getStartFortuneUI = () => {
        return (
            //分析入運
            analysisResult &&
            <StartFortune className={`${styles.horosopeTextArea}`}
                horosopeResult={horosopeResult} inputDate={inputDate} isMale={isMale} />
        )
    }

    const onTaiSuiYearSelected = (year) => {
        console.log("onTaiSuiYearSelected" + year)
        //  props.setSelectedYear(year)
    }


    const getTaiSuiUI = () => {
        return (
            //分析太歲
            analysisResult &&
            <>
                <UseGod
                    useGod1={useGod1} setUseGod1={setUseGod1}
                    useGod2={useGod2} setUseGod2={setUseGod2}
                    description='輸入用神可增加太歲分析結果'
                    onClick={() => { setTaiSuiQueryTime(Date.now()) }} />


                <TaiSui
                    className={`${styles.horosopeTextArea}`}
                    useGod1={useGod1} useGod2={useGod2}
                    taiSuiQueryTime={taiSuiQueryTime} inputDate={props.inputDate}
                    isMale={props.isMale}
                    onTaiSuiYearSelected={onTaiSuiYearSelected}
                    isAnalysisReady={props.isAnalysisReady} //if analysis is ready, start getting TaiSui
                />


            </>

        )


    }

    const getSalaryUI = () => {
        return (
            //分析祿
            horosopeResult &&
            <Salary className={`${styles.horosopeTextArea}`}
                analysisResult={horosopeResult} endOriginal8Letter={endOriginal8Letter}
                addressSchema={props.addressSchema} />
        )
    }

    const getMsonSelectorUI = () => {
        return (
            <div className={`${styles.gridContainerSingleRowLayoutSpaced}`}>
                {props.isDLetterReady && props.addressSchema && <div>
                    <MsonSelector horoscopeData={props.horoscopeData} addressSchema={props.addressSchema}
                        msonIdPartyShowList={props.msonIdPartyShowList} setMsonIdPartyShowList={props.setMsonIdPartyShowList}
                        shouldWrapWithDraggable={false}
                    />
                </div>}
                <MsonSelectorUI />
            </div>
        )
    }

    const getRelationTableUI = () => {
        return (
            horosopeResult && horosopeResult.letterAddress && horosopeResult.letterAddress.string
                && horosopeResult.letterAddress.string.length > 5 ?
                <RelationTable isMale={isMale} dayStemLetter={horosopeResult.letterAddress.string[4]} /> : null
        )
    }

    const tabDefinition = [
        { name: '旺弱 ', tabContent: () => getStrongWeakUI() },
        //{ name: '用神', tabContent: () => getUseGodUI() },
        { name: '組合清單 ', tabContent: () => getMsonSelectorUI() },
        { name: '太歲', tabContent: () => getTaiSuiUI() },
        { name: '十神關係', tabContent: () => getRelationTableUI() },
        { name: '陽刃', tabContent: () => getPositiveDaggerUI() },
        { name: '祿', tabContent: () => getSalaryUI() },
        // { name: '原局重點事件', tabContent: '' },
        // { name: '原局基本分析', tabContent: '' },
        // { name: '原局所有組合', tabContent: getCombinationUI() },

        // { name: '流年重點事件', tabContent: '' },
        // { name: '流年基本分析', tabContent: '' },
        { name: '入運', tabContent: () => getStartFortuneUI() },
        { name: '流年所有組合', tabContent: () => getCombinationMonthUI() },

        // { name: '事業', tabContent: '' },
        // { name: '財運', tabContent: '' },
        // { name: '健康', tabContent: '' },
        // { name: '家庭關係', tabContent: '' },
        // { name: '性格', tabContent: '' },
        // { name: 'Debug', tabContent: () => getDebugUI() },

    ];

    function getTabs() {
        return tabDefinition.map((definition, index) => ({
            title: definition.name,
            getContent: () => definition.tabContent(),
            /* Optional parameters */
            key: index,
            tabClassName: 'tab',
            panelClassName: 'panel',
            transform: false
        }));
    }

    return (
        <>
            <Tabs items={getTabs()} transform={false} />
        </>
    )

}

