import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import styles from './index.module.css'
import { get } from '../../../axios'
import { compareBirthday } from "../../../Helper/DateTimeHelper";
import { globalData } from "../../../Helper/global";

import { parseHoroscope, parseDetailsOfLetterAddressToText } from "../../../Helper/AddressObj"

/**
 * 天干組合地支
 * test case:
 * http://localhost:3000/inputDate/1982-08-31T15:25/ismale/true 4酉
 * 
 * @param {*} props 
 * @returns 
 */
export default function Combination(props) {

    const [combinationText, setCombinationText] = useState("")  //display all 天干組合地支
    const [combinationMonthText, setCombinationMonthText] = useState("")  //display selected 天干組合地支

    const [selectedYear, setSelectedYear] = useState('');
    const [selectedCombinationMonth, setSelectedMonth] = useState('');

    const [horosope8LetterOnlyResult, setHorosope8LetterOnlyResult] = useState();   //store 原局   analysis result
    const [horosopeYearOnlyResult, setHorosopeYearOnlyResult] = useState(); //store single year analysis result

    const [availableCombinationYears, setAvailableCombinationYears] = useState([]); // Replace with your state
    const [availableCombinationMonth] = useState(['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']);
    const original8Letter = "原局"
    const pleaseSelect = "請選擇"

    useEffect(() => {

        if (props.analysisResult !== undefined) {
            getAnalysisPerYear(0)   //get 原局
            //取得可用年
            console.log("getAvailableCombinationYears")
            setAvailableCombinationYears([original8Letter, ...getStartEndYearOfLife(props.analysisResult)])
        }
    }, [props.analysisResult])

    /**
     * draw selected 天干組合地支
     */
    useEffect(() => {
        if (horosopeYearOnlyResult) {
            //parse selected 天干組合地支
            setCombinationMonthText(parseCombinationAllDate(horosopeYearOnlyResult, undefined, false))
        }
    }, [horosopeYearOnlyResult])

    /**
     * draw 原局 和 所有月份的天干組合地支
     */
    useEffect(() => {
        if (props.analysisResult && props.analysisResult.letterAddressAllDate && combinationText.length == 0
            && horosope8LetterOnlyResult) {
            //parse 原局天干組合地支
            const eightLetterCombination = parseEightLetterOnly(horosope8LetterOnlyResult)

            //parse 所有月份的天干組合地支
            setCombinationText("原局:\r\n" + (eightLetterCombination || "並無組合\r\n"))
        }
    }, [horosope8LetterOnlyResult, props.analysisResult])    //



    const handleCombinationYearChange = (event) => {
        const year = event.target.value == original8Letter ? 0 : event.target.value
        setSelectedYear(year)
        if (selectedCombinationMonth && selectedCombinationMonth !== pleaseSelect) {
            getAnalysisPerYearMonth(year, selectedCombinationMonth)
        } else {
            getAnalysisPerYear(year)
        }
    };

    const handleCombinationMonthChange = (event) => {
        setSelectedMonth(event.target.value)
        if (selectedYear) {
            if (event.target.value === pleaseSelect) {
                getAnalysisPerYear(selectedYear)
            } else {
                getAnalysisPerYearMonth(selectedYear, event.target.value)
            }
        }
    };


    /**
     * Parse all 原局 with address schema
     */
    const parseEightLetterOnly = (analysisResult) => {
        if (analysisResult && analysisResult.letterAddressAllDate) {

            //Get any key value pair of month for eight letter only. Therefore, get the first one
            //0 is first item. 1 is value of key value pair
            const address = Object.entries(analysisResult.letterAddressAllDate)[0][1].string
            const details = Object.entries(analysisResult.letterAddressAllDate)[0][1].details

            return parseDetailsOfLetterAddressToText(address, details, props.addressSchema)
        }
        return undefined
    }



    /**
     * 取大運流年的組合
     * @param {*} year 
     */
    const getAnalysisPerYear = (year) => {
        if (year !== undefined && !isNaN(year)) // check variable is dateTime 
        {
            get(`api/birthday/analysis/${props.inputDate}/ismale/${props.isMale}/fortune/0/year/${year}?t=${Date.now()}`).then(res => {
                if (res.data.success) {
                    if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {
                        if (year == 0) {
                            //case: Get 原局
                            setHorosopeYearOnlyResult(parseHoroscope(res.data.data))
                        } else {
                            //case: Get single year
                            setHorosopeYearOnlyResult(parseHoroscope(res.data.data))
                        }
                    } else {
                        console.log("Horosope birthday " + res.data.data.birthday + " is not up to date.")
                    }
                }
            })
        }
    }

    /**
      * 取大運流年的組合
      * @param {*} year 
      */
    const getAnalysisPerYearMonth = (year, month) => {
        if (year !== undefined && !isNaN(year)) // check variable is dateTime 
        {
            get(`api/birthday/analysis/${props.inputDate}/ismale/${props.isMale}/fortune/0/year/${year}/month/${month}?t=${Date.now()}`).then(res => {
                if (res.data.success) {
                    if (compareBirthday(res.data.data.birthday, globalData.inputDate)) {
                        //case: Get single year
                        setHorosopeYearOnlyResult(parseHoroscope(res.data.data))
                    } else {
                        console.log("Horosope birthday " + res.data.data.birthday + " is not up to date.")
                    }
                }
            })
        }
    }

    /**
   * parse all combination with date time
   * @param {*} analysisResult - the source of data from server 
   * @param {*} dateTime - If datetime is available, only get the elaboration of 大運流年流月 and return a string of elaboration. If undefined, loop through all and return keyvalue pair <date, string> per month 
    * @param {*} showDate - whether show date for each analysis
    * @returns string presentation of all 大運流年流月
   */
    const parseCombinationAllDate = (analysisResult, dateTime, showDate = true) => {

        if (analysisResult && analysisResult.letterAddressAllDate && Object.entries(analysisResult.letterAddressAllDate).length > 0) {

            //No specified dateTime, so loop all year and month
            if (dateTime == undefined) {
                //loop all dateTime
                let monthParseResult = ""

                const entries = Object.entries(analysisResult.letterAddressAllDate)
                for (const [key, value] of entries) {
                    if (value !== undefined) {
                        const address = value.string
                        const details = value.details

                        let dateString = showDate ? key.split("T")[0] + ":\r\n" : ""
                        monthParseResult += (dateString
                            + (parseDetailsOfLetterAddressToText(address, details, props.addressSchema) || "並無組合\r\n")
                            + "\r\n"
                        )
                    }
                }
                return monthParseResult

            } else {
                if (dateTime !== undefined && dateTime instanceof Date && !isNaN(dateTime)) // check variable is dateTime 
                {
                    const entries = Object.entries(analysisResult.letterAddressAllDate)

                    //get object according to specified dateTime
                    let dateMatchedYearMonth = entries.find(([key, value]) => {
                        const dateCompared = new Date(key)
                        return dateCompared.getFullYear() === dateTime.getFullYear() && dateCompared.getMonth() === dateTime.getMonth()
                    })

                    //get result according to specified dateTime
                    if (dateMatchedYearMonth) {
                        const address = dateMatchedYearMonth[1].string
                        const details = dateMatchedYearMonth[1].details

                        const result = parseDetailsOfLetterAddressToText(address, details, props.addressSchema)
                            + "\r\n"
                        return result
                    } else {
                        return undefined
                    }
                }
            }
        }
    }



    /**
    * Get a year array from start of life until end of horoscope
    * @param {*} horoscopeData 
    * @returns 
    */
    const getStartEndYearOfLife = (horoscopeData) => {
        let yearArray = []

        if (horoscopeData && horoscopeData.letterAddressAllDate) {
            const entries = Object.entries(horoscopeData.letterAddressAllDate)
            if (entries.length > 0) {
                const startDate = new Date(entries[0][0]) //1st [0] is date and 2nd [0] is key
                const endDate = new Date(entries[entries.length - 1][0]) //1st [0] is date and 2nd [0] is key

                if (startDate instanceof Date && endDate instanceof Date) {
                    for (let index = 0; index < endDate.getFullYear() - startDate.getFullYear(); index++) {
                        yearArray[index] = startDate.getFullYear() + index
                    }
                }
            }
        }
        return yearArray
    }


    return (<>
        {/* <div className={`${styles.tableContainer}`} style={{ margin: "0px", width: "100%", maxWidth: "none" }}>
            <div className={`${styles.tableRow}`}>
                <div className={`${styles.tableCell2}`}>所有組合</div>
            </div>
        </div>
        <textarea className={`${styles.horosopeTextArea}`} readOnly value={combinationText}>
        </textarea>

        <div style={{ margin: "10px 0px 0px 0px", width: "100%" }} /> */}


        <div className={`${styles.tableContainer}`} style={{ margin: "0px", width: "100%", maxWidth: "none" }}>
            <div className={`${styles.tableRow}`}>


                <div className={`${styles.tableCell2}`}>請選擇年月篩選組合</div>

                <div className={`${styles.tableCell}`}>年:</div>
                <div className={`${styles.tableCell}`}>

                    <select value={selectedYear} onChange={handleCombinationYearChange}>
                        {/* <option value={pleaseSelect}>{pleaseSelect}</option> */}
                        {availableCombinationYears.map((year, index) => (
                            <option key={index} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
                <div className={`${styles.tableCell}`}></div>
                <div className={`${styles.tableCell}`}></div>

                <div className={`${styles.tableCell}`}>月:</div>
                <div className={`${styles.tableCell}`}>

            <select value={selectedCombinationMonth} onChange={handleCombinationMonthChange} disabled={selectedYear === 0}>
                        <option value={pleaseSelect}>{pleaseSelect}</option>
                        {availableCombinationMonth.map((month, index) => (
                            <option key={index} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
            </div>
        </div>

        <textarea className={`${styles.horosopeTextArea}`} readOnly value={combinationMonthText}>
        </textarea>

        {/* <textarea className={props.className} readOnly value={parsePositiveDaggerAnalysis(props.analysisResult)}>
        </textarea> */}
    </>)

}

Combination.propTypes = {
    analysisResult: PropTypes.any.isRequired, // replace 'any' with the expected type of 'status1'
    addressSchema: PropTypes.any.isRequired
}