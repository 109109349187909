import React, { useEffect, useState } from "react";
import styles from "./index.module.css"
import YearFortune100 from "../YearFortune100";
import ClientDataInput from "../ClientDataInput";
import Horosope from "../Horosope";
import { useLocation, useNavigate } from 'react-router-dom';
import { globalData } from "../../Helper/global";
import VToolbar from "./VToolbar";
import EightLetters from "../EightLetters";
import ArrowLegend from "../ArrowLegend";
import ScrollableList from "../ScrollableList";
import useScreenSizeCheck from "../../hook/useScreenSizeCheck";
import logo192 from '../../images/logo192.png';

// import Tabs from 'react-responsive-tabs'; //tabpage
// import 'react-responsive-tabs/styles.css';//tabpage


export default function EightLettersPage(props) {

  const location = useLocation()
  const [eightLettersResult, setEightLettersResult] = useState()  // store the result chineseDate get request
  const [isMale, setIsMale] = useState(true)
  const [inputDate, setInputDate] = useState()

  const [selected10YearRange, setSelected10YearRange] = useState()

  //for selection ui in YearFortune
  const [selectedYear10, setSelectedYear10] = useState(0)
  const [selectedYear10IndexNumber, setSelectedYear10IndexNumber] = useState(0)
  const [selectedYear, setSelectedYear] = useState(0)   //set selected year in YearFortune
  const [selectedMonth, setSelectedMonth] = useState(0) //set selected month in YearFortune
  const [yearfortune100Result, setYearfortune100Result] = useState()
  const [year10Ready, setYear10Ready] = useState(false) //record whether yearfortune is ready
  const [isAnalysisReady, setIsAnalysisReady] = useState(false);
  // const [isYearFortuneReady, setIsYearFortuneReady] = useState(false);  

  //for extra info
  const extraInfoMaskValue = "11111111111111111111"
  const [extraInfoMask, setExtraInfoMask] = useState(extraInfoMaskValue)

  const selectedLetterIndexValue = "4-O"
  const [selectedLetterIndex, setSelectedLetterIndex] = useState(selectedLetterIndexValue)
  const [selectedLetter, setSelectedLetter] = useState()

  const [addressSchema, setAddressSchema] = useState()    //the json content of address.json

  const [isDLetterReady, setIsDLetterReady] = useState(false);

  const [featureList, setFeatureList] = useState([
    "列出見不見之形。 例如: 天干丁己，組合成午",
    "列出暗拱地支。例如:子寅拱丑，巳丑拱酉",
    "列出原局、流年、流月配合以上結果計算合化、合局、天克地沖和刑冲破害",
    "點擊主中餘氣切換立極點",
    "列出桃花、驛馬、陽刃、祿位和十二長生",
    "以八字和用神， 查詢每年跟太歲的關係",
    "根據性別和陰陽日元，列出十神和親屬朋友的關係",
    "以八字反查詢出生日期，方便閱讀古書"]);

  const isSmallScreen = useScreenSizeCheck();

  //For mson selector passed from eightletter to horosope
  const [msonIdPartyShowList, setMsonIdPartyShowList] = useState({});
  const [horoscopeData, setHoroscopeData] = useState(); //hold data upon user's selected year month




  /**
   * Support bookmark url
   */
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    if (pathParts[1] === 'inputDate' && pathParts[3] === 'ismale') {

      //for checking birthday and filter outdated server reply
      globalData.inputDate = pathParts[2]

      setInputDate(pathParts[2]);
      setIsMale(pathParts[4] === 'true');
    }
  }, [location]);


  /**
   * Reset old data when inputDate or isMale change
   */
  useEffect(() => {
    if (!inputDate) return

    window.history.replaceState(null, '', `/inputDate/${inputDate}/ismale/${isMale}`);

    //reset old data
    setSelectedYear10(0)
    setSelectedYear(0)
    setSelectedMonth(0)
    setYearfortune100Result(undefined)
    setYear10Ready(false)
    setIsAnalysisReady(false)
    console.log("setIsAnalysisReady to false")
    //reset 
    setSelectedLetter(undefined)
    setSelectedLetterIndex(selectedLetterIndexValue)

    setExtraInfoMask(extraInfoMaskValue)


  }, [inputDate, isMale])


  //downloading json content of address.json when component loaded
  useEffect(() => {
    fetch('/config/address.json')
      .then(response => response.json())
      .then(data => setAddressSchema(data));
  }, []);

  //to be called by child component
  //get chinese eight letter from western date
  // const getBirthDate = (inputDateParam) => {
  //   // /api/birthday/chinesedate/1982-07-31T13%3A05'

  //   //for checking birthday and filter outdated server reply
  //   globalData.inputDate = inputDateParam

  //   setInputDate(inputDateParam)
  // }





  const drawYearCombo = () => {
    return (
      <div>
        <select id="iyear" name="iyear">
          <option value="1901">1901</option><option value="1902">1902</option><option value="1903">1903</option><option value="1904">1904</option><option value="1905">1905</option><option value="1906">1906</option><option value="1907">1907</option>
          <option value="1908">1908</option><option value="1909">1909</option><option value="1910">1910</option><option value="1911">1911</option><option value="1912">1912</option><option value="1913">1913</option><option value="1914">1914</option>
          <option value="1915">1915</option><option value="1916">1916</option><option value="1917">1917</option><option value="1918">1918</option><option value="1919">1919</option><option value="1920">1920</option><option value="1921">1921</option>
          <option value="1922">1922</option><option value="1923">1923</option><option value="1924">1924</option><option value="1925">1925</option><option value="1926">1926</option><option value="1927">1927</option><option value="1928">1928</option>
          <option value="1929">1929</option><option value="1930">1930</option><option value="1931">1931</option><option value="1932">1932</option><option value="1933">1933</option><option value="1934">1934</option><option value="1935">1935</option>
          <option value="1936">1936</option><option value="1937">1937</option><option value="1938">1938</option><option value="1939">1939</option><option value="1940">1940</option><option value="1941">1941</option><option value="1942">1942</option>
          <option value="1943">1943</option><option value="1944">1944</option><option value="1945">1945</option><option value="1946">1946</option><option value="1947">1947</option><option value="1948">1948</option><option value="1949">1949</option>
          <option value="1950">1950</option><option value="1951">1951</option><option value="1952">1952</option><option value="1953">1953</option><option value="1954">1954</option><option value="1955">1955</option><option value="1956">1956</option>
          <option value="1957">1957</option><option value="1958">1958</option><option value="1959">1959</option><option value="1960">1960</option><option value="1961">1961</option><option value="1962">1962</option><option value="1963">1963</option>
          <option value="1964">1964</option><option value="1965">1965</option><option value="1966">1966</option><option value="1967">1967</option><option value="1968">1968</option><option value="1969">1969</option><option value="1970">1970</option>
          <option value="1971">1971</option><option value="1972">1972</option><option value="1973">1973</option><option value="1974">1974</option><option value="1975">1975</option><option value="1976">1976</option><option value="1977">1977</option>
          <option value="1978">1978</option><option value="1979">1979</option><option value="1980">1980</option><option value="1981">1981</option><option value="1982">1982</option><option value="1983">1983</option><option value="1984">1984</option>
          <option value="1985">1985</option><option value="1986">1986</option><option value="1987">1987</option><option value="1988">1988</option><option value="1989">1989</option><option value="1990">1990</option><option value="1991">1991</option>
          <option value="1992">1992</option><option value="1993">1993</option><option value="1994">1994</option><option value="1995">1995</option><option value="1996">1996</option><option value="1997">1997</option><option value="1998">1998</option>
          <option value="1999">1999</option><option value="2000">2000</option><option value="2001">2001</option><option value="2002">2002</option><option value="2003">2003</option><option value="2004">2004</option>
          <option value="2005">2005</option><option value="2006">2006</option><option value="2007">2007</option><option value="2008">2008</option><option value="2009">2009</option><option value="2010">2010</option><option value="2011">2011</option>
          <option value="2012">2012</option><option value="2013">2013</option><option value="2014">2014</option><option value="2015">2015</option><option value="2016">2016</option><option value="2017">2017</option><option value="2018">2018</option>
          <option value="2019">2019</option><option value="2020">2020</option><option value="2021">2021</option><option value="2022">2022</option><option value="2023">2023</option><option value="2024">2024</option><option value="2025">2025</option>
          <option value="2026">2026</option><option value="2027">2027</option><option value="2028">2028</option><option value="2029">2029</option><option value="2030">2030</option><option value="2031">2031</option><option value="2032">2032</option>
          <option value="2033">2033</option><option value="2034">2034</option><option value="2035">2035</option><option value="2036">2036</option><option value="2037">2037</option><option value="2038">2038</option><option value="2039">2039</option>
          <option value="2040">2040</option><option value="2041">2041</option><option value="2042">2042</option><option value="2043">2043</option><option value="2044">2044</option><option value="2045">2045</option><option value="2046">2046</option>
          <option value="2047">2047</option><option value="2048">2048</option><option value="2049">2049</option><option value="2050">2050</option><option value="2051">2051</option><option value="2052">2052</option><option value="2053">2053</option>
          <option value="2054">2054</option><option value="2055">2055</option><option value="2056">2056</option><option value="2057">2057</option><option value="2058">2058</option><option value="2059">2059</option><option value="2060">2060</option>
          <option value="2061">2061</option><option value="2062">2062</option><option value="2063">2063</option><option value="2064">2064</option><option value="2065">2065</option><option value="2066">2066</option><option value="2067">2067</option>
          <option value="2068">2068</option><option value="2069">2069</option><option value="2070">2070</option><option value="2071">2071</option><option value="2072">2072</option><option value="2073">2073</option><option value="2074">2074</option>
          <option value="2075">2075</option><option value="2076">2076</option><option value="2077">2077</option><option value="2078">2078</option><option value="2079">2079</option><option value="2080">2080</option><option value="2081">2081</option>
          <option value="2082">2082</option><option value="2083">2083</option><option value="2084">2084</option><option value="2085">2085</option><option value="2086">2086</option><option value="2087">2087</option><option value="2088">2088</option>
          <option value="2089">2089</option><option value="2090">2090</option><option value="2091">2091</option><option value="2092">2092</option><option value="2093">2093</option><option value="2094">2094</option><option value="2095">2095</option>
          <option value="2096">2096</option><option value="2097">2097</option><option value="2098">2098</option><option value="2099">2099</option>
        </select> 年

        <select id="bmonth" name="bmonth">
          <option value="1">1</option><option value="2">2</option><option value="3">3</option><option value="4">4</option><option value="5">5</option>
          <option value="6" selected="">6</option><option value="7">7</option><option value="8">8</option><option value="9">9</option>
          <option value="10">10</option><option value="11">11</option><option value="12">12</option>
        </select> 月



      </div>
    )
  }

  const drawPageWithoutBirthday = () => {
    return (
      <>
        <nav className={styles.navbar}>
          <div className={styles.logo}>
            <img src={logo192} className={styles.logoWidth} />
            <span className={styles.title}>Adacs - 子平八字排盤</span>
          </div>
        </nav>

        <div className={`${styles.fullScreenContainer} `}>


          <div className={styles.gridContainer1Columns}>
            <div>特色:</div>

            <div className={styles.gridContainerSingleRowLayoutSpaced}>
              {/* {window.innerWidth || document.documentElement.clientWidth } */}
              {/* <img src={logo192}  className={styles.logoWidth} /> */}
              <div>
                <ScrollableList featureList={featureList} />
              </div>

            </div>
            <center>
              <ClientDataInput
                // getBirthDate={getBirthDate}
                setInputDate={setInputDate} inputDate={inputDate}
                isMale={isMale} setIsMale={setIsMale}
                getSolarTime={props.getSolarTime}
                requireDateComfirmation={true} />
            </center>


          </div>
        </div>
      </>
    )
  }


  const drawPageWithBirthday = () => {
    return <div className={styles.container}>

      {/* tab page for date input */}
      <div className={styles.gridContainerSingleRowLayoutSpaced}>

        <ClientDataInput
          //  getBirthDate={getBirthDate}
          setInputDate={setInputDate} inputDate={inputDate}
          isMale={isMale} setIsMale={setIsMale}
          getSolarTime={props.getSolarTime} />


        {isDLetterReady && !isSmallScreen && <ArrowLegend />}

      </div>



      {/* {drawYearCombo()} */}
      {/* <VToolbar extraInfoMask={extraInfoMask} setExtraInfoMask={setExtraInfoMask} /> */}


      {/* <div style={{ height: "10px" }}></div> */}
      <EightLetters inputDate={inputDate} isMale={isMale}
        extraInfoMask={extraInfoMask}
        selectedYear10={selectedYear10} selected10YearRange={selected10YearRange}


        yearfortune100Result={yearfortune100Result}
        selectedYear={selectedYear} selectedMonth={selectedMonth}
        eightLettersResult={eightLettersResult}
        setEightLettersResult={setEightLettersResult}

        selectedLetterIndex={selectedLetterIndex} setSelectedLetterIndex={setSelectedLetterIndex}
        selectedLetter={selectedLetter} setSelectedLetter={setSelectedLetter}

        year10Ready={year10Ready}
        selectedYear10IndexNumber={selectedYear10IndexNumber}
        addressSchema={addressSchema}

        // to indicate wither deviated letter are ready
        isDLetterReady={isDLetterReady}
        setIsDLetterReady={setIsDLetterReady}

        // for mson selector
        // share from eightLetter to horoscope tab page
        msonIdPartyShowList={msonIdPartyShowList}
        setMsonIdPartyShowList={setMsonIdPartyShowList}
        horoscopeData={horoscopeData}
        setHoroscopeData={setHoroscopeData}

      />




      {inputDate && eightLettersResult ?
        <details open={true}>
          <summary>分析</summary>
          <Horosope inputDate={inputDate} isMale={isMale}
            year10Ready={year10Ready}
            setSelectedYear10={setSelectedYear10}
            setSelectedYear={setSelectedYear}
            setSelectedMonth={setSelectedMonth}
            selectedYear10IndexNumber={selectedYear10IndexNumber}
            isAnalysisReady={isAnalysisReady}
            setIsAnalysisReady={setIsAnalysisReady}
            addressSchema={addressSchema}

            // to indicate wither deviated letter are ready
            isDLetterReady={isDLetterReady}
            setIsDLetterReady={setIsDLetterReady}

            // for mson selector
            // share from eightLetter to horoscope tab page
            msonIdPartyShowList={msonIdPartyShowList}
            setMsonIdPartyShowList={setMsonIdPartyShowList}
            horoscopeData={horoscopeData}
            setHoroscopeData={setHoroscopeData}

          /></details>
        : null}



      {/* {drawYearFortune100()} */}

      {inputDate && eightLettersResult ? <YearFortune100 inputDate={inputDate} isMale={isMale}
        setSelected10YearRange={setSelected10YearRange}
        setSelectedYear10={setSelectedYear10}
        setSelectedYear10IndexNumber={setSelectedYear10IndexNumber}
        setSelectedYear={setSelectedYear}
        setSelectedMonth={setSelectedMonth}
        setYearfortune100Result={setYearfortune100Result}
        setYear10Ready={setYear10Ready}

        selectedLetterIndex={selectedLetterIndex} setSelectedLetterIndex={setSelectedLetterIndex}
        selectedLetter={selectedLetter} setSelectedLetter={setSelectedLetter} extraInfoMask={extraInfoMask}


      /> : null}

    </div>
  }


  return (
    inputDate ? drawPageWithBirthday() : drawPageWithoutBirthday()
  );



} 