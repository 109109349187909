import React, { useEffect, useState } from "react";
import PropTypes from 'prop-types';

export default function PositiveDagger(props) {


    const [positiveDaggerObj, setPositiveDaggerObj] = useState();


    const parsePositiveDaggerAnalysis = (analysisResult) => {
        //get 原局出鞘
        let result = ""

        for (const dateTime in analysisResult.letterAddressAllDate) {
            let address = analysisResult.letterAddressAllDate[dateTime].string

            if (result.length == 0) {
                //case: 1st time to extract 原局
                let eightLetterStringReturned = findPositiveDagger(analysisResult.letterAddressAllDate[dateTime].positiveDaggerResult, dateTime, address, true)
                if (eightLetterStringReturned.length > 0) {
                    result += (eightLetterStringReturned + "\r\n")
                }
            }

            //case: 2nd time or later extract 流年
            let fortuneStringReturned = findPositiveDagger(analysisResult.letterAddressAllDate[dateTime].positiveDaggerResult, dateTime, address, false)
            if (fortuneStringReturned.length > 0) {
                result += (fortuneStringReturned + "\r\n")
            }
        }

        return result.length > 0 ? result : "並無組合"

    }


    function findPositiveDagger(dagger, dateTime, address, isEightLetteronly = false) {
        let eightLetterResult = ""
        if (dagger != undefined) { //dagger is not empty
            //get 陽刃地址
            let daggerArr = dagger["daggerIndex"];
            if (daggerArr.length > 0) {
                for (const i of daggerArr) {    //loop dagger

                    //only process i <= endOriginal8Letter for 原局
                    //only process i > endOriginal8Letter for 流年
                    if (isEightLetteronly && i <= props.endOriginal8Letter || (!isEightLetteronly && i > props.endOriginal8Letter)) {
                        const schema = props.addressSchema.find(x => x.Index == i);
                        if (eightLetterResult.length != 0) {
                            eightLetterResult += ",";
                        }
                        eightLetterResult += `${schema.pillar}${schema.skyearth}[${address[i]}]`
                    }
                }

                if (isEightLetteronly && eightLetterResult.length > 0) {
                    eightLetterResult = "- 原局: 陽刃 " + eightLetterResult
                } else if (!isEightLetteronly && eightLetterResult.length > 0) {
                    eightLetterResult = `-${dateTime.split("T")[0]} : 陽刃 ${eightLetterResult}`
                }

                //process 出鞘 if 陽刃 exist
                if (eightLetterResult.length > 0) {
                    //get 出鞘陽刃地址
                    eightLetterResult = findShowUpPositiveDagger(dagger, isEightLetteronly, address, eightLetterResult)

                    //get 沖歳君
                    eightLetterResult = findRushYear(dagger, address, eightLetterResult)


                    //get 合歳君
                    eightLetterResult = findCombineYear(dagger, address, eightLetterResult)

                }
            }
        }
        return eightLetterResult.length == 0 && isEightLetteronly ? "原局並無陽刃" : eightLetterResult;
    }

    return (<>
        <textarea className={props.className} readOnly value={parsePositiveDaggerAnalysis(props.analysisResult)}>
        </textarea>
    </>)

    function findRushYear(dagger, address, eightLetterResult) {
        let eightLetterShowUpResult = "";
        let rushArr = dagger["rushYearIndex"];
        if (rushArr.length > 0) {

            for (let i = 0; i < rushArr.length; i += 2) {
                const schema = props.addressSchema.find(x => x.Index == rushArr[i]);
                const schema2 = props.addressSchema.find(x => x.Index == rushArr[i + 1]);

                if (eightLetterShowUpResult.length == 0) {
                    eightLetterShowUpResult += ", 沖歳君於 ";
                } else {
                    eightLetterShowUpResult += " ";
                }
                eightLetterShowUpResult += `${schema.pillar}${schema.skyearth}[${address[rushArr[i]]}]<->${schema2.pillar}${schema2.skyearth}[${address[rushArr[i + 1]]}]`;
            }

            eightLetterResult += eightLetterShowUpResult;
        }

        return eightLetterResult;
    }




    function findCombineYear(dagger, address, eightLetterResult) {
        let eightLetterShowUpResult = "";
        let combineArr = dagger["combineYearIndex"];
        if (combineArr.length > 0) {

            for (let i = 0; i < combineArr.length; i += 2) {
                const schema = props.addressSchema.find(x => x.Index == combineArr[i]);
                const schema2 = props.addressSchema.find(x => x.Index == combineArr[i + 1]);

                if (eightLetterShowUpResult.length == 0) {
                    eightLetterShowUpResult += ", 合歳君於 ";
                } else {
                    eightLetterShowUpResult += " ";
                }
                eightLetterShowUpResult += `${schema.pillar}${schema.skyearth}[${address[combineArr[i]]}]<->${schema2.pillar}${schema2.skyearth}[${address[combineArr[i + 1]]}]`;
            }

            eightLetterResult += eightLetterShowUpResult;
        }

        return eightLetterResult;
    }

    function findShowUpPositiveDagger(dagger, isEightLetteronly, address, eightLetterResult) {
        let eightLetterShowUpResult = "";
        let showUpArr = dagger["showUpIndex"];
        if (showUpArr.length > 0) {
            for (const i of showUpArr) {

                //only process i <= endOriginal8Letter for 原局
                //process all position of 出鞘 for 流年. It is possible 流年有陽刃, 出鞘在原局
                if (isEightLetteronly && i <= props.endOriginal8Letter || (!isEightLetteronly)) {
                    const schema = props.addressSchema.find(x => x.Index == i);
                    if (eightLetterShowUpResult.length == 0) {
                        eightLetterShowUpResult += ", 出鞘於 ";
                    } else {
                        eightLetterShowUpResult += " ";
                    }
                    eightLetterShowUpResult += `${schema.pillar}${schema.skyearth}[${address[i]}]`;
                }

            }
            eightLetterResult += eightLetterShowUpResult;
        } else {
            eightLetterResult += " 並無出鞘";
        }
        return eightLetterResult;
    }
}

PositiveDagger.propTypes = {
    className: PropTypes.string,
    analysisResult: PropTypes.any.isRequired, // replace 'any' with the expected type of 'status1'
    endOriginal8Letter: PropTypes.number.isRequired,
    addressSchema: PropTypes.any.isRequired
}